import { type FormEvent, useState } from 'react';
import { httpPost } from '../lib/http.ts';
import { toast } from 'sonner';

export function SubscribeBanner() {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    const { response, error } = await httpPost(
      `${import.meta.env.PUBLIC_API_URL}/v1-create-newsletter-subscription`,
      {
        email,
      },
    );

    if (error || !response) {
      setIsLoading(false);
      toast.error(error?.message || 'Something went wrong');

      return;
    }

    setEmail('');
    setIsLoading(false);
    toast.success('You have been subscribed!');
  };

  return (
    <div className="pb-12 pt-0">
      <div className="container">
        <div className="max-w-[500px]">
          <p className="mb-4 text-3xl font-medium text-gray-900">
            <span className="mb-1 inline-block bg-black px-2 py-1 text-white">
              Want product news and updates?
            </span>
            Sign up for our newsletter.
          </p>

          <form onSubmit={handleSubmit}>
            <div>
              <div className="flex space-x-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  placeholder="Enter your email"
                  spellCheck="false"
                  autoCorrect="off"
                  autoCapitalize="off"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="settings-none block flex flex-1 rounded-md border border-gray-300 px-3 py-3 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none"
                />
                <button
                  type="submit"
                  disabled={isLoading || !email}
                  className="group inline-block rounded-lg bg-black px-6 py-3 text-lg text-white"
                >
                  {isLoading ? 'Please wait...' : 'Subscribe'}
                </button>
              </div>

              <p className="mt-3 text-sm text-gray-500">
                We hate spam as much as you do. You can unsubscribe at any time.
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
